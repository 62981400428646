// Colors
$primary-color: #233948;
$secondary-color: #317982;
$font-color: #fffffe;

.super-app-theme--header {
  background-color: $secondary-color;
  color: $font-color;
}

.main-data-grid {
  height: 850px;
  width: 100%;
  margin-top: 65px;
}

.ban-data-grid {
  height: 850px;
  width: 105%;
  margin-top: 65px;
  display: flex;
  justify-content: flex-start
}

.offset-input {
  height: 850px;
  width: 105%;
  margin-top: 65px;
  margin-left: 300px;
  display: flex;
  justify-content: flex-start
}

.ngo-data-grid {
  margin-left: 300px;
  height: 280px;
  width: 52%;
  margin-top: 65px;
}

.user-trades-data-grid,
.user-ads-data-grid,
.ad-trades-grid {
  margin-top: 30px;
  height: 730px;
  width: 100%;
}

.user-trades-header,
.user-ads-header,
.ad-trades-header {
  text-align: center;
}

.page-details {
  margin-top: 65px;
  margin-left: 300px;
  border-radius: 5px;
  padding: 10px;
  //max-width: 100%;
  overflow-x: hidden;
  //text-align: -moz-right;
}

.page-details form {
  width: 1000px;
}

.page-details-first-part,
.page-details-second-part {
  display: inline-grid;
  width: 32%;
  margin: 25px;
  margin-left: 130px;
}

.page-details-without-form {
  width: 17%;
  margin: 25px;
}

.footer {
  bottom: 0;
  width: 61%;
  text-align: center;
}

input[type="text"],
input[type="password"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="submit"] {
  width: 100%;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: $primary-color;
}

.report-resolve-input{
  width: 25%;
  padding: 14px 20px;
  margin: 10px 0 10px;
  border-radius: 4px;
  border-color: gray;
}

.resolve-button{
  width: 8%;
  padding: 14px 20px;
  margin: 10px 0 10px;
  border-radius: 4px;
  border-color: gray;
  background-color: #233948;
  color: white;
}

.button {
  width: 100%;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: $primary-color;
}

.profile {
  width: 200px;
  height: 250px;
  margin: 100px;
  position: absolute;
  text-align: center;
}

.profile img {
  width: 200px;
  height: 200px;
}

.login {  
  display: flex;  
  flex-direction: column;  
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login form {
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
}

.closeModalButton {
  left: 47%;
}

.header-title {
  display: flex;
  justify-content: space-around;
  padding: 15px 10px;
  background-color: $secondary-color;
  margin-top: 64px;

  div {
    color: $font-color;
  }
}

